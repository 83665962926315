import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container"; 
// LOCAL CUSTOM COMPONENT

import AboutLinks from "./components/about-links";
import SocialLinks from "./components/social-links";
import CustomerCareLinks from "./components/customer-care-links"; 
// GLOBAL CUSTOM COMPONENTS

import LazyImage from "components/LazyImage";
import { Paragraph } from "components/Typography";
import FlexBetween from "components/flex-box/flex-between"; 
// STYLED COMPONENT

import { Heading } from "./styles";
export default function Footer4() {
  return <Box component="footer" bgcolor="white" pt={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            {/*<Box maxWidth={100}>
              <LazyImage src={require("../../../public/assets/images/haha-logo-new.png")} alt="logo" />
            </Box>*/}

            <Paragraph mb={2.5} maxWidth={{
            xl: 500
          }}>
              - Get latest halfprice specials from Coles and Woolworths for free.<br/>
              - Build up your half-price shopping list and save you time and money.<br/>
              - It's great to stock up only when it's half price, right?<br/>
              - All data is updated on every Wednesday morning.
              </Paragraph>
          </Grid>

          {
          /* ABOUT US LINKS */
        }
          <Grid item lg={2} md={2} sm={6} xs={12}>
            
          </Grid>

          {
          /* CUSTOMER CARE LINKS */
        }
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CustomerCareLinks isDark />
          </Grid>

          {
          /* CONTACT INFORMATION */
        }
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Heading>Contact Us</Heading>

            <Paragraph py={0.6}>
              Adelaide, South Austrlia
            </Paragraph>

            <Paragraph py={0.6}>Email: takaokanji@gmail.com</Paragraph>
          </Grid>
        </Grid>

        <Box component={Divider} mt={{
        md: 8,
        xs: 3
      }} />

        <FlexBetween pt={2} pb={{
        sm: 10,
        md: 2
      }}>
          <Paragraph>© 2024 By Pgyer.</Paragraph>
          <SocialLinks variant="dark" />
        </FlexBetween>
      </Container>
    </Box>;
}