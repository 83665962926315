"use client";

import { Fragment, useCallback, useState, useEffect } from "react";
import { usePathname } from "next/navigation";
import Divider from "@mui/material/Divider"; 
// GLOBAL CUSTOM COMPONENTS

import Sticky from "components/sticky";
import Topbar from "components/topbar";
import { Footer4 } from "components/footer";
import Header from "components/header";
import NavigationList from "components/navbar/nav-list/nav-list";
import { MobileNavigationBar } from "components/mobile-navigation";
/**
 *  USED IN:
 *  1. GADGET-2 | FURNITURE-2 | MEDICAL | GROCERY-1
 */

export default function ShopLayout3({
  children
}) {
  const pathname = usePathname();
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback(fixed => setIsFixed(fixed), []);
  const CENTERED = ["/medical", "/gift-shop", "/grocery-1"];
  const STYLE = CENTERED.includes(pathname) ? {
    marginInline: "auto"
  } : {
    marginRight: "auto",
    marginLeft: "2rem"
  };
  const HEADER_SLOT = <div style={STYLE}>
      <NavigationList />
    </div>;

  useEffect(() => {
    // Load Google Analytics
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-M6CDTH52K8";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-M6CDTH52K8');
    `;
    document.head.appendChild(script2);

    // Cleanup scripts if component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);  

  return <Fragment>
      {
      /* TOP BAR SECTION */
    }
      <Topbar />

      {
      /* HEADER */
    }
      <Sticky fixedOn={0} onSticky={toggleIsFixed} scrollDistance={300}>
        <Header isFixed={isFixed} midSlot={HEADER_SLOT} />
        <Divider />
      </Sticky>

      {
      /* BODY CONTENT */
    }
      {children}

      {
      /* SMALL DEVICE BOTTOM NAVIGATION */
    }
      <MobileNavigationBar />

      {
      /* FOOTER */
    }
      <Footer4 />
    </Fragment>;
}